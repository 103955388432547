<script>
import Print from './LeilaoPrintPage'
import {relatorioSaida} from "@/domain/leiloes/services"
import { VueMaskDirective as mask } from 'v-mask'
import * as Status from '@/domain/leiloes/helpers/LoteStatus'
import {URadio, UCheckbox} from 'uloc-vue'

export default {
  directives: {mask},
  mixins: [Print],
  components: {URadio, UCheckbox},
  nome: 'LeilaoRelatoriosSaida',
  data() {
    let situacaoLote = [
      {label: 'Vendidos', value: Status.STATUS_VENDIDO, class: 'btn-default'}, /*,  class: 'btn-info' */
      {label: 'Condicionais', value: Status.STATUS_CONDICIONAL, class: 'btn-default'}, /*,  class: 'btn-primary' */
      {label: 'Sem licitantes', value: Status.STATUS_SEM_LICITANTES, class: 'btn-default'}, /* , class: 'btn-success' */
      {label: 'Baixa Oferta', value: Status.STATUS_BAIXA_OFERTA, class: 'btn-default'}, /* , class: 'btn-success' */
      {label: 'Retirados', value: Status.STATUS_RETIRADO, class: 'btn-default'} /* , class: 'btn-success' */
    ]
    return {
      dados: null,
      leilaoData: null,
      tiposLeilao: [
        {label: 'Qualquer', value: '', class: 'btn-default'},
        {label: 'Online', value: 2, class: 'btn-default'},
        {label: 'Presencial', value: 1, class: 'btn-default'}
      ],
      situacaoLote: situacaoLote,
      filterKey: '',
      sortKey: '',
      filtro: {
        tipo: '',
        situacao: situacaoLote.map(a => a.value)
      },
      sortOrders: {
        'numero': 1,
        'titulo': 1,
        'comitente': 1
      },
      showFiltros: false
    }
  },
  computed: {
    filteredData: function () {
      let sortKey = this.sortKey
      let filterKey = this.filterKey && this.filterKey.toLowerCase()
      let filtrosFixos = this.filtro
      let order = this.sortOrders[sortKey] || 1
      let data = this.leilaoData.lotes
      // let tipo = this.filtro.tipo
      if (filterKey) {
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
      }

      // Situação
      data = data.filter(function (row) {
        return filtrosFixos.situacao.some(el => el === row.status)
      })

      // Tipo
      console.log(filtrosFixos.tipo)
      if (filtrosFixos.tipo) {
        data = data.filter(function (row) {
          if (!row.arremate) {
            return false // Não foi arrematado
          }
          let test
          if (filtrosFixos.tipo === 2) { // Online
            test = row.arremate.arrematante.apelido !== 'PLATEIA'
          } else if (filtrosFixos.tipo === 1) { // Presencial
            test = row.arremate.arrematante.apelido === 'PLATEIA'
          } else {
            test = true
          }
          return test
        })
      }

      if (sortKey) {
        data = data.slice().sort(function (a, b) {
          a = a[sortKey]
          b = b[sortKey]
          return (a === b ? 0 : a > b ? 1 : -1) * order
        })
      }
      return data
    },
    getStatus () {
      return Status.Status
    },
    Status () {
      return Status
    }
  },
  mounted() {
  },
  activated() {
  },
  created() {
    this.isLoading = true
    relatorioSaida(this.leilao.id)
        .then((response) => {
          this.dados = response.data
          this.leilaoData = response.data
          this.isLoading = false
        })
        .catch((response) => {
          // this.isLoading = false
          this.alertApiError(response)
          console.log(response)
        })
  },
  deactivated() {
  },
  watch: {},
  methods: {
    bindMargemClass (arremate, minimo) {
      let sum = Number(arremate) - Number(minimo)
      return {
        'text-danger': sum < 0,
        'text-success': sum >= 0
      }
    }
  }
}
</script>

<template>
  <print-relatorio :is-loading="isLoading">
    <div v-if="!isLoading" class="relatorio-saida-bens">
      <div v-if="dados && dados.template" v-html="dados.template.template"></div>

      <div v-else style="padding: 20px">
        <div class="hidden-print">
          <div class="text-center m-t">
            <u-btn @click="showFiltros = !showFiltros" class="btn"><span class="btn btn-default btn-xs m-r-xs">{{ !showFiltros ? '+' : '-' }}</span>Filtros</u-btn>
          </div>
          <div class="r-table" v-show="showFiltros">
            <div class="r-table-content">
              <div class="m-t">
                <div class="m-b">
                  <div class="m-b-sm">Tipo de Venda:</div>
<!--                  <u-btn-group v-model="filtro.tipo" :options="tiposLeilao"></u-btn-group>-->
                  <u-radio v-model="filtro.tipo" :val="''" label="Qualquer" class="m-r-sm" />
                  <u-radio v-model="filtro.tipo" :val="2" label="Online" class="m-r-sm" />
                  <u-radio v-model="filtro.tipo" :val="1" label="Presencial" />
                </div>
                <div class="m-b">
                  <div class="m-b-sm">Situação:</div>
<!--                  <u-btn-group :multiSelect="true" v-model="filtro.situacao"
                                  :options="situacaoLote"></u-btn-group>-->
                  <u-checkbox v-for="(item, i) in situacaoLote" :key="i" v-model="filtro.situacao" :label="item.label" :val="item.value" class="m-r-sm" />
                </div>
                <div class="m-b">
                  Resultados: {{ filteredData && filteredData.length }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden-print">
          <h2 class="text-center text-uppercase font-bold m-b-none">Relatório de Saída de Bens</h2>
          <h4 class="text-center text-uppercase">Leilão {{ leilaoData.codigo }}</h4>
        </div>
        <div v-for="(lote, key) in filteredData" class="printer-page simulador-folha-a4 pos-rlt">
          <div class="visible-print">
            <h2 class="text-center text-uppercase font-bold m-b-none">Relatório de Saída de Bens</h2>
            <h4 class="text-center text-uppercase">Leilão  {{ leilaoData.codigo }}</h4>
          </div>
          <div class="r-table">
            <div class="r-table-header">
              <div class="pull-left font-bold text-black">{{ lote.descricao }}</div>
              <div class="pull-right font-bold">Lote {{ lote.numero }}</div>
              <div class="clearfix"></div>
            </div>
            <div class="r-table-content">
              <table class="w-full" cellpadding="0" cellspacing="0">
                <tr>
                  <td>Comitente: <strong>{{ lote.bem.comitente.pessoa.name }}</strong></td>
                  <td class="text-right">Situação: <strong style="-webkit-print-color-adjust: exact;" :class="'lote-status scolor ' + Status.Status[lote.status].class">{{
                      Status.Status[lote.status].title }}</strong></td>
                </tr>
              </table>
              <div class="tab-border m-t-lg">
                <table class="w-full table-arremate">
                  <thead>
                  <tr>
                    <th>
                      Valor de Arremate
                    </th>
                    <th class="col-comissao">
                      Comissão
                    </th>
                    <th class="col-taxas">
                      Taxas/Impostos
                    </th>
                    <th style="width: 18%; white-space: nowrap">
                      Mínimo
                    </th>
                    <th style="width: 18%; white-space: nowrap">
                      Pagamento
                    </th>
<!--                    <th style="width: 18%; white-space: nowrap">
                      Margem
                    </th>-->
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <span v-if="lote.arremate">R$ {{ lote.valorArremate | moeda }}</span>
                    </td>
                    <td class="col-comissao">
                      <span v-if="lote.arremate">R$ {{ lote.valorComissao | moeda }}</span>
                    </td>
                    <td class="col-taxas">
                      <span v-if="lote.arremate">R$ {{ (lote.valorImpostos - lote.valorComissao) | moeda }}</span>
                    </td>
                    <td>
                      R$ {{ lote.valorMinimo | moeda }}
                    </td>
                    <td>
                      <span v-if="lote.arremate">{{ lote.arremate.statusString }}</span>
                    </td>
<!--                    <td class="margem">
                      <span v-if="lote.arremate" :class="bindMargemClass(lote.valorArremate, lote.valorMinimo)">R$ {{ Number(lote.valorArremate) - Number(lote.valorMinimo) | moeda }}</span>
                    </td>-->
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="m-t" v-if="lote.arremate">
                <table class="w-full print-table print-table-bordered m-b-none">
                  <tbody>
                  <tr>
                    <td>Arrematante</td>
                    <td colspan="3" class="font-bold">{{ lote.arremate.arrematante.pessoa.name }}
                      ({{ lote.arremate.arrematante.apelido }}) #A{{ lote.arremate.arrematante.id }}-P{{
                        lote.arremate.arrematante.pessoa.id }}

                      <!--<table class="w-full">
                        <tr>
                          <td>CPF</td>
                          <td>{{ lote.arremate.arrematante.pessoa.pfCpf }}</td>
                        </tr>
                      </table>-->


                    </td>
                  </tr>
                  <tr v-if="lote.arremate.arrematante.pessoa.tipo === 1">
                    <td>Tratamento</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pessoa.treatment }}</td>
                    <td>Sexo</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pessoa.gender === 1 ? 'M' : 'F' }}</td>
                  </tr>
                  <tr v-if="lote.arremate.arrematante.pessoa.type === 1">
                    <td>CPF</td>
                    <td class="font-bold"><span v-if="lote.arremate.arrematante.pessoa.document">{{ lote.arremate.arrematante.pessoa.document | formataCpf }}</span><span v-else>SEM CPF</span></td>
<!--                    <td>RG</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pessoa.pfRg }} {{
                        lote.arremate.arrematante.pessoa.pfRgOrgaoEmissor }}
                    </td>-->
                  </tr>
                  <tr v-if="lote.arremate.arrematante.pessoa.type === 1">
                    <td>Data de Nascimento</td>
                    <td class="font-bold">
                      <span v-if="lote.arremate.arrematante.pessoa.birthDate">{{lote.arremate.arrematante.pessoa.birthDate.date|formatDate('dd/MM/yyyy')}}</span>
                      <span v-else>-</span>
                    </td>
                    <td>Estado Civil</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.estadoCivilString }}</td>
                  </tr>
<!--                  <tr
                      v-if="lote.arremate.arrematante.pessoa.type === 1 && (lote.arremate.arrematante.pfFiliacaoNomePai || lote.arremate.arrematante.pessoa.pfFiliacaoNomeMae)">
                    <td>Nome do Pai</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pessoa.pfFiliacaoNomePai }}</td>
                    <td>Nome da Mãe</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pessoa.pfFiliacaoNomeMae }}</td>
                  </tr>-->
                  <tr v-if="lote.arremate.arrematante.pessoa.type === 2">
                    <td>Razão Social</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pessoa.name ||
                    lote.arremate.arrematante.pessoa.name }}
                    </td>
<!--                    <td>Nome Fantasia</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pessoa.pjNomeFantasia }}</td>-->
                  </tr>
                  <tr v-if="lote.arremate.arrematante.pessoa.type === 2">
                    <td>CNPJ</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pessoa.document|formataCnpj }}</td>
<!--                    <td>Inscrição Estadual</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pessoa.pjInscricaoEstadual }}</td>-->
                  </tr>
                  <tr v-if="lote.arremate.arrematante.pessoa.type === 2">
                    <td>Responsável</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pjProprietarioNome }}</td>
                    <td>CPF</td>
                    <td class="font-bold"><span v-if="lote.arremate.arrematante.pjProprietarioCpf">{{ lote.arremate.arrematante.pjProprietarioCpf | formataCpf }}</span><span v-else>SEM CPF DO RESPONSÁVEL</span></td>
                  </tr>
                  <tr>
                    <td>IP Cadastro</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.pessoa.ipRegistration }}</td>
                    <td>Observação</td>
                    <td class="font-bold">{{ lote.arremate.arrematante.observacao }}</td>
                  </tr>
                  </tbody>
                </table>

                <!-- ENDEREÇOS -->
                <div class="tab-border no-border-top">
                  <table class="w-full table-arremate">
                    <thead>
                    <tr>
                      <th>
                        Endereço
                      </th>
                      <th style="width: 18%; white-space: nowrap">
                        Cidade
                      </th>
                      <th style="width: 18%; white-space: nowrap">
                        UF
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="endereco in lote.arremate.arrematante.pessoa.addresses">
                      <td>
                        {{ endereco.address }}, {{ endereco.number }} {{ endereco.complement }}, {{ endereco.district
                        }}, {{ endereco.zip | formataCep }}
                      </td>
                      <td>
                        {{ endereco.city }} <!-- cityId ? -->
                      </td>
                      <td>
                        {{ endereco.state }} <!-- stateId -->
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <!-- CONTATOS -->
                <div class="tab-border no-border-top">
                  <table class="w-full table-arremate">
                    <thead>
                    <tr>
                      <th>
                        Email
                      </th>
                      <th style="width: 18%; white-space: nowrap">
                        Finalidade
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="email in lote.arremate.arrematante.pessoa.emails">
                      <td>
                        {{ email.email }}
                      </td>
                      <td>
                        {{ email.purpose ? email.purpose.name : '-' }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <!-- TELEFONES -->
                <div class="tab-border no-border-top">
                  <table class="w-full table-arremate">
                    <thead>
                    <tr>
                      <th>
                        Telefone
                      </th>
                      <th style="width: 18%; white-space: nowrap">
                        Finalidade
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="telefone in lote.arremate.arrematante.pessoa.phoneNumbers">
                      <td>
                        {{ telefone.phoneNumber | formataTelefone }}
                      </td>
                      <td>
                        {{ telefone.purpose ? telefone.purpose.name : '-' }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <!--<table class="w-full table table-bordered m-b-none">
                  <thead>
                  <tr>
                    <th>
                      Arrematante
                    </th>
                    <th style="width: 10%; white-space: nowrap">
                      CPF
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ lote.arremate.arrematante.pessoa.nome }}
                      ({{ lote.arremate.arrematante.apelido }})

                      &lt;!&ndash;<table class="w-full">
                        <tr>
                          <td>CPF</td>
                          <td>{{ lote.arremate.arrematante.pessoa.pfCpf }}</td>
                        </tr>
                      </table>&ndash;&gt;


                    </td>
                    <td>{{ lote.arremate.arrematante.pessoa.pfCpf }}</td>
                  </tr>
                  </tbody>
                </table>
                <table class="w-full table table-bordered m-t-none no-borders">
                  <thead>
                  <tr>
                    <th>
                      RG
                    </th>
                    <th style="width: 10%; white-space: nowrap">
                      ÓRGÃO EMISSOR
                    </th>
                    <th style="width: 20%; white-space: nowrap">
                      EMAIL
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ lote.arremate.arrematante.pessoa.nome }}
                      ({{ lote.arremate.arrematante.apelido }})

                      &lt;!&ndash;<table class="w-full">
                        <tr>
                          <td>CPF</td>
                          <td>{{ lote.arremate.arrematante.pessoa.pfCpf }}</td>
                        </tr>
                      </table>&ndash;&gt;


                    </td>
                    <td>{{ lote.arremate.arrematante.pessoa.pfCpf }}</td>
                    <td>{{ lote.arremate.arrematante.pessoa.pfCpf }}</td>
                  </tr>
                  </tbody>
                </table>-->
              </div>

            </div>
          </div>
          <div class="p-page-numer visible-print">Página {{key+1}} de {{filteredData && filteredData.length}}</div>
        </div>
      </div>
    </div>
    <!--<div class="p-page-numer visible-print">Página {{key+1}} de {{filteredData && filteredData.length}}</div>-->
  </print-relatorio>
</template>
